// Path: src\Component\screens\widgets\myTournamentStructure.js
import React, { useEffect, useState } from "react";
import { Box, Grid, LinearProgress, Button, capitalize } from "@mui/material";
import { ReactComponent as Clipcopy } from "../../../../src/assets/images/clipcopy.svg";
import Tournaments from "../../../../src/assets/images/Tournaments.png";
import RightArrow from "../../../../src/assets/images/right-arrow.png"
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import url from "../../../constants/url";
import WinningBreakup from "../../Popup/WinningBreakup";
import { tournamentPricePointPoolApi } from "../../../apis";

import Loader from "../../loader";
import { formatDate } from "../../Utilities/helper";

const MyTournamentStructure = ({ handleTournamentClick, tournaments }) => {
  const [isPricePointPoolModel, setPricePointPool] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [prizePool, setPrizePool] = useState('');
  const [isLoading, setIsLoader] = useState(false);

  const totalSlots = tournaments.totalPlayers;
  const slotsFilled = tournaments.totalJoinedPlayers;
  const percentageFilled = (slotsFilled / totalSlots) * 100;
  const redFillWidth = tournaments?.totalJoinedPlayers >= tournaments?.minimumSlot ? 0 : ((tournaments?.minimumSlot || 0) / totalSlots) * 100; // Width of the red fill
  async function fetchPricePointPoolData(tournament) {
    setIsLoader(true);
    try {
      let response = await tournamentPricePointPoolApi(tournament?._id);
      if (response?.success) {
        setIsLoader(false);
        setPrizePool(response?.data);
      }
      else {
        setIsLoader(false);
        setPrizePool('');
      }
      setPricePointPool(true)
    } catch (error) {
      setIsLoader(false);
      setPrizePool('');
      console.log("getTournament error");
    }
  }

  //Handle Card Click this way due to event propogation issue
  const [clicked, setClicked] = useState(false);
  const handleContestClickPoint = () => {
    setClicked(true)
    setSelectedItem(tournaments);
    fetchPricePointPoolData(tournaments)
  };
  const handleContestClick = () => {
    if (!clicked) {
      handleTournamentClick(tournaments)
    }
    setClicked(false)
  };

  return (
    isLoading ? <Loader />
      :
      <>
        <div className="newcontestbox-list" onClick={() => handleContestClick()}>
          <div className="newcontest-list-title-bar mt-3">
            <div className="newcontestbox-list-title">
              <div className="newcontestbox-list-title">
                {tournaments?.title?.length > 30 ? <marquee>{tournaments?.title}</marquee> : tournaments?.title}
              </div>
              {/* <div className="superpower-title">hsbdhjsbdhjsd sd sd fdnsf dsj fsdjfsdjf s dsdjkfdsj</div>  */}
            </div>

            <div className="newcontestbox-list-status">
              {capitalize(tournaments?.status)}
            </div>
          </div>

          {/* basic details */}
          <div className="row newcontest-basicdetails">
            <div className="row newtournament-basicdetails">
              <div className="col-4 newtournament-image-wrapper">
                <div className="newtournament-image-container">
                  {tournaments?.featuredImage?.default ? (
                    <img src={url.image_url + tournaments?.featuredImage?.default} alt="" className="newtournament-image" />
                  ) : (
                    <img src={Tournaments} alt="" className="newtournament-image" />
                  )}
                  <span className="newtournament-image-image-text">{tournaments?.gameType?.name}</span>
                </div>
              </div>
              <div className="col-8 newtournament-details--wrap ps-2">

                <div className="newcontest">
                  <div className="newcontestbox-list-label">Date</div>
                  <div className="newcontestbox-list-value">
                    <span className="value-wrapper"> {formatDate(tournaments?.startDate)}</span>
                  </div>
                </div>

                <div className="newcontest">
                  <div className="newcontestbox-list-label">Rounds</div>
                  <div className="newcontestbox-list-value">
                    <span className="value-wrapper">{tournaments?.roundsCount}</span>
                  </div>
                </div>

                <div className="newcontest">
                  <div className="newcontestbox-list-label">ID</div>
                  <div className="newcontestbox-list-value">
                    <span className="value-wrapper" onClick={() => { navigator.clipboard.writeText(tournaments?.shortCode); }}>
                      {tournaments?.shortCode}
                    </span>
                  </div>
                </div>

                <div className="newtournament--wrap">
                  {tournaments?.titles?.[0]?.isSelection && tournaments?.titles?.[0]?.name && tournaments?.titles?.[0]?.value && (
                    <div className="newcontest">
                      <div className="newcontestbox-list-label">{tournaments?.titles?.[0]?.name}</div>
                      <div className="newcontestbox-list-value">
                        <span className="value-wrapper" style={{
                          display: 'inline-block',
                          maxWidth: '9ch', // limits to 9 characters width
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}>
                          {tournaments?.titles?.[0]?.value}
                        </span>

                        <span className="value-wrapper truncate-map-box"> {tournaments?.titles?.[0]?.value}</span>
                      </div>
                    </div>
                  )}

                  {tournaments?.titles?.[1]?.isSelection && tournaments?.titles?.[1]?.name && tournaments?.titles?.[1]?.value && (
                    <div className="newcontest">
                      <div className="newcontestbox-list-label">{tournaments?.titles?.[1]?.name}</div>
                      <div className="newcontestbox-list-value">
                        <span className="value-wrapper truncate-map-box">{tournaments?.titles?.[1]?.value}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

            </div>
            <><div className="newtournament-prizedetails">
                <div className="newcontest-prize--wrap">
                  <div className="newcontest">

                    <div className="newcontestbox-list-label">Prize Pool</div>
                    <div className="newcontestbox-list-value-icon">
                      {tournaments?.prizePoolType === 'amount' &&
                        <span>
                          <img className="newcontest-list-currency-icon1" src={url?.image_url +
                            tournaments?.currency?.outCurrency?.img?.default}
                            alt="" />
                        </span>}
                      <span className="newcontest-list-currency-amount value-wrapper">  {tournaments?.prizePoolType === 'amount' ? tournaments?.prizePool : tournaments?.rewardDisplayText}</span>
                    </div>
                  </div>
                  <div className="newcontest">
                    <div className="newcontestbox-list-label">Winners</div>
                    <div className="newcontestbox-list-value-icon-dropdown">
                      <div className="newcontest-list-currency-amount value-wrapper">
                        {tournaments?.totalWinners}
                      </div>
                      <span className="newcontest-list-currency-amount value-wrapper" onClick={(e) => {
                        e.stopPropagation();
                        handleContestClickPoint();
                      } }>
                        <img className="newcontest-list-currency-icon1" src={RightArrow} alt="" style={{ marginRight: '3px' }} />

                      </span>


                    </div>
                  </div>
                </div>
                <div className="newcontest">
                  <div className="newcontestbox-list-label">Join Using</div>
                  <div className="newcontestbox-list-value-icon">
                    <span className="newcontest-list-currency-amount value-wrapper">
                      {tournaments?.entryFee > 0 ?
                        <img className="newcontest-list-currency-icon1" src={url.image_url +
                          tournaments?.currency?.inCurrency?.img?.default} alt="" /> : <></>} {tournaments?.entryFee > 0 ? tournaments?.entryFee : "Free"}
                    </span>
                  </div>
                </div>
              </div><Grid container spacing={2} className="details-grid slots-info">
                  <Grid item xs={12} className="details-grid-box">
                    <div className="progress-bar-wrapper custome-progressbar">
                      <LinearProgress
                        variant="determinate"
                        value={percentageFilled}
                        className="custom-linear-progress" />
                      <Tooltip
                        title={`Minimum ${tournaments?.minimumSlot} ${tournaments?.minimumSlot > 1 ? 'slots' : 'slot'} required`}
                        arrow
                        placement="top"
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "#1F3338",
                              border: "1px solid #87A7A6",
                              color: "#86A6A6", // Text color
                              fontSize: "0.75rem",
                              filter: "drop-shadow(0px 14px 74px rgba(0, 0, 0, 0.25))",
                              borderRadius: "4px",
                              padding: "8px",
                              width: "162px",
                              height: "50.758px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            },
                            "& .MuiTooltip-arrow": {
                              color: "#1F3338", // Arrow background color
                              "&::before": {
                                boxShadow: "0 0 0 1px #86A6A6", // Simulating white border
                              },
                            },
                          },
                        }}
                        classes={{ tooltip: "custom-tooltip" }}>
                        <div
                          className="progress-bar-red-fill"
                          style={{
                            width: `${redFillWidth}%`,
                            left: `${percentageFilled}%`,
                            position: "absolute",
                            height: "100%",
                          }} />
                      </Tooltip>
                    </div>
                  </Grid>
                  <Grid item xs={6} className="details-grid-box">
                    <div className="slots-remaining">{tournaments?.totalPlayers - tournaments?.totalJoinedPlayers} {(tournaments?.totalPlayers - tournaments?.totalJoinedPlayers) > 1 ? 'slots' : 'slot'} remaining</div>
                  </Grid>
                  <Grid item xs={6} textAlign="right" className="details-grid-box">
                    <div className="slots-filled">{tournaments?.totalJoinedPlayers} {tournaments?.totalJoinedPlayers > 1 ? 'slots' : 'slot'} filled</div>
                  </Grid>
                </Grid><div className="newcontest-roomidpass-container">
                  <Grid container spacing={2} className="details-grid slots-info">
                    <Grid
                      item
                      xs={6}
                      textAlign="center"
                      className="details-grid-box"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(tournaments?.contestsList?.roomId);
                      } }>
                      <div className="newcontest-room-id-label newcontest-details-label">Room ID</div>
                      <div className="newcontest-room-id-value">
                        {tournaments?.contestsList?.roomId !== "" ? (
                          <>
                            {" "}
                            {tournaments?.contestsList?.roomId?.length > 15 ?
                              <marquee> {tournaments?.contestsList?.roomId}</marquee>
                              : tournaments?.contestsList?.roomId}{" "}
                            <span>
                              <Clipcopy />
                            </span>
                          </>
                        ) : (
                          '-'
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      textAlign="center"
                      className="details-grid-box details-grid-box-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(tournaments?.contestsList?.roomPassword);
                      } }>
                      <div className="newcontest-password-label newcontest-details-label">Password</div>
                      <div className="newcontest-room-id-value">
                        {tournaments?.contestsList?.roomPassword !== "" ? (
                          <>
                            {tournaments?.contestsList?.roomPassword?.length > 15 ?
                              <marquee> {tournaments?.contestsList?.roomPassword}</marquee>
                              : tournaments?.contestsList?.roomPassword}{" "}
                            <span>
                              <Clipcopy />
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div></>
          </div>

        </div>
        {isPricePointPoolModel && (
          <WinningBreakup
            isOpen={isPricePointPoolModel}
            title="Winning Breakup"
            onClose={() => setPricePointPool(false)}
            prizePool={prizePool}
            tournaments={selectedItem}
          />
        )}
      </>
  );
};

export default MyTournamentStructure;
